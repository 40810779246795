export const VARIABLES = {
  environment: process.env.REACT_APP_ENV,
  baseURL: process.env.REACT_APP_BASE_API_URL,
  awsURL: process.env.REACT_APP_AWS_URL,
  stripePublicKey: process.env.REACT_APP_PUBLIC_KEY
};

const publicRoutes = {
  landing: "/",
  login: "/login",
  signup: "/signup",
  forgotPassword: "/forgot-password",
  resetPassword: "/reset-password",
};

const protectedRoutes = {
  home: "/home",
  browse: "/home/categories",
  favourites: "/home/favourites",
  sessions: "/session",
  sessionDetails: ":sessionID",
  privacyPolicy: `/privacy-policy`,
  faq: "/faq",
  tos: "/terms-of-service",
  pricing: "/pricing",
  checkout: "/checkout/:id",
  Addcard: "/Add-card",
  success: "/success",
  profile: "/profile",
  changePassword: "/changepassword",
  dashboard: "/dashboard",
};

const authRoutes = {
  verify: "/verify",
  ...protectedRoutes,
};

export const ROUTES = {
  ...publicRoutes,
  ...authRoutes,
};

export const LOCAL_STORAGE_KEY = "minmind-pwa";
