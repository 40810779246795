const authUrls = {
  signUp: "auth/signup",
  signIn: "auth/signin",
  verify: "auth/verify",
  forgotPassword: "auth/forgotpassword",
  resetPassword: "auth/resetpassword",
  changePassword: "auth/changepassword",
  /**
   * Pass the user ID to return the customized URL string
   * @param {*} id
   * @returns {string} url
   */
  resend: (id) => `auth/resend/${id}`,
  refreshUser: "auth/refreshtoken",
};

const configUrls = {
  presigned: "config/presignUrl",
};

const userUrls = {
  profile: "user/profile",
  categories: "user/categories",
  category: (id) => `user/categories/${id}`,
  subcategories: "user/subcategories",
  sessions: "user/sessions",
  session: (id) => `user/session/${id}`,
  favouriteSession: (id) => `user/sessions/${id}`,
  modules: "user/modules",
  lessons: "user/lessons",
};

const subscribeUrls = {
  getStripeUrl: "subscription/plans",
  getStripeCardUrl: "subscription/stripe/card",
  addStripeCardUrl: "subscription/stripe/card",
  createSubscriptionUrl: "subscription/subscribe",
}

const URL = {
  ...authUrls,
  ...configUrls,
  ...userUrls,
  ...subscribeUrls,
};

export default URL;
