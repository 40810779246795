import React from "react";

const WithoutHeader = ({ children }) => {
  return (
    <div className="main-cont-wrapper mm-without-header mm-gradient">
      <section className="site-section" style={{ overflowY: "hidden" }}>
        <div className="container mm-height">
          <div className="row mm-height">
            <div className="col-lg-6 mx-auto">{children}</div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default WithoutHeader;
