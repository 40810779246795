import { VARIABLES } from "constant";

/**
 * Function to convert seconds into duration timer. This will show appropriate display in hh:mm:ss.
 * @param {*} seconds
 * @returns {string} duration
 */
export const durationDisplay = (seconds) => {
  const h = Math.floor(seconds / 3600);
  const m = Math.floor((seconds - h * 3600) / 60);
  const s = Math.floor(seconds - m * 60);

  const hours = h >= 1 ? `${h > 9 ? "0" : ""}${h}:` : "";
  const mins = m <= 9 ? `0${m}:` : `${m}:`;
  const secs = s <= 9 ? `0${s}` : s;

  return `${hours}${mins}${secs}`;
};

/**
 * Returns base URL for the image from s3
 * @param {string} baseUrl
 * @returns
 */
export const getFileUrl = (baseUrl) => `${VARIABLES.awsURL}/${baseUrl}`;
export const getstripeKey = () => `${VARIABLES.stripePublicKey}`;


/**
 * Creates a deep copy of an array or an object using JSON stringify -> parse technique.
 * @param {*} object
 * @returns
 */
export const deepClone = (object) => JSON.parse(JSON.stringify(object));
