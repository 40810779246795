import React from "react";
import { Link } from "react-router-dom";
import thumbanail1 from "../../../assets/images/thumbnails/thumbnail1.png";

const Success = () => {
  return (
    <>
  <div className="site-wrapper mm-gradient">
        <div className="main-cont-wrapper  mm-without-header">
          <section className="site-section img-text-section">
            <div className="container mm-height">
              <div className="rm-flex-between">
                <div className="row mm-height">
                  <div className="col-md-6 col-lg-4 mx-auto">
                    <div className="minmind-card-wrapper">
                      <figure className="mm-card-fig text-center mt-5">
                        <img src={thumbanail1} alt="resources-tools-img" />
                      </figure>

                      <article className="mm-article mb-4 ">
                        <h1 className="ft-weight-700 font-sf-ui-display mm-text-primary text-center">
                          Successfully Purchased
                        </h1>

                        <p className="mm-text-primary ft-weight-500 text-center">
                          Thank you for purchasing subscription.
                        </p>
                      </article>
                      <div className="mm-btn-wrapper text-center mt-5">
                       <Link to={"/"}> <button
                          type="submit"
                          className="theme_btn theme_primary w-100"
                        >
                          Continue
                        </button>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
};

export default Success;
