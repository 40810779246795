import * as api from "api/user";
import produce from "immer";
import { toast } from "react-toastify";

export const userActions = (set, get) => ({
  fetchAllCategories: () => {
    const token = get()?.auth?.access;

    const fetchSuccessful = api
      .getAllCategories(token)
      .then((res) => {
        const { data } = res;
        set(
          produce((state) => {
            state.data.categories = data?.categories;
          }),
          false,
          `Fetched all categories`
        );
        return true;
      })
      .catch((err) => {
        // console.log("Error fetching categories" + err);
        toast.error("Unable to fetch categories at the moment.");
        return false;
      });

    return fetchSuccessful;
  },

  fetchAllSubcategories: (categoryID = "") => {
    const token = get()?.auth?.access;

    if (Boolean(categoryID)) {
      const fetchSuccessful = api
        .getAllSubcategories(token, categoryID)
        .then((res) => {
          const { data } = res;
          // console.log("Fetched subcategories", data.subcategories);
          set(
            produce((state) => {
              state.data.subcategories = data?.subcategories;
            }),
            false,
            `Updated subcategories for Category [${categoryID}]`
          );
          return true;
        })
        .catch((err) => {
          // console.log("Error fetching subcategories" + err);
          toast.error("Unable to fetch subcategories at the moment.");
          return false;
        });
      return fetchSuccessful;
    } else {
      set(
        produce((state) => {
          state.data.subcategories = null;
        }),
        false,
        `Reset subcategories data`
      );
      return false;
    }
  },
  fetchAllSessions: ({categoryID = "", subcategoryID = "", favourite = ""}) => {
    const token = get()?.auth?.access;

    if (Boolean(categoryID) || favourite) {
      const fetchSuccessful = api
        .getAllSessions(token, categoryID, subcategoryID, favourite)
        .then((res) => {
          const { data } = res;
          set(
            produce((state) => {
              state.data.sessions = data?.sessions;
            }),
            false,
            `Updated sessions for Category [${categoryID}]${
              subcategoryID.length > 0
                ? ` & Subcategory [${subcategoryID}]`
                : ""
            }`
          );
          return true;
        })
        .catch((err) => {
          // console.log("Error fetching sessions" + err);
          toast.error("Unable to fetch sessions at the moment.");
          throw new Error("Unable to fetch sessions at the moment.");
        });

      return fetchSuccessful;
    } else {
      set(
        produce((state) => {
          state.data.sessions = null;
        }),
        false,
        `Reset sessions data`
      );
      return false;
    }
  },
  fetchSessionByID: (sessionID = "") => {
    const token = get()?.auth?.access;

    if (Boolean(sessionID)) {
      const fetchSuccessful = api
        .getSessionByID(token, sessionID)
        .then((res) => {
          const { data } = res;
          const categoryID = data.session.categories.id;
          const subcategoryID = data.session.sub_categories.id;
          get()?.fetchAllSessions({categoryID, subcategoryID});
          return true;
        });
      return fetchSuccessful;
    }
  },
  fetchAllModules: (sessionID = "") => {
    const token = get()?.auth?.access;

    if (Boolean(sessionID)) {
      const fetchSuccessful = api
        .getAllModules(token, sessionID)
        .then((res) => {
          const { data } = res;
          set(
            produce((state) => {
              state.data.modules = data?.modules;
            }),
            false,
            `Updated modules for Session [${sessionID}]`
          );
          return true;
        })
        .catch((err) => {
          // console.log("Error fetching modules" + err);
          toast.error("Unable to fetch modules at the moment.");
          return false;
        });

      return fetchSuccessful;
    } else {
      set(
        produce((state) => {
          state.data.modules = null;
        }),
        false,
        `Reset modules data`
      );
      return false;
    }
  },
  fetchAllLessons: (moduleID = "") => {
    const token = get()?.auth?.access;

    if (Boolean(moduleID)) {
      const fetchSuccessful = api
        .getAllLessons(token, moduleID)
        .then((res) => {
          const { data } = res;
          // console.log("Lessons", data);
          set(
            produce((state) => {
              state.data.lessons = data?.lessons;
            }),
            false,
            `Updated lessons for Module [${moduleID}]`
          );
          return true;
        })
        .catch((err) => {
          // console.log("Error fetching lessons" + err);
          toast.error("Unable to fetch lessons at the moment.");
          return false;
        });

      return fetchSuccessful;
    } else {
      set(
        produce((state) => {
          state.data.lessons = null;
        }),
        false,
        `Reset lessons data`
      );
      return false;
    }
  },
  getUserProfile: () => {
    const token = get().auth.access;

    const fetchSuccessful = api
      .getUserProfile(token)
      .then((res) => {
        const { data } = res;
        set(
          {
            activeUser: { ...data.user },
          },
          false,
          `Set active user [${data?.user?.email}]`
        );
        return data;
      })
      .catch((err) => {
        // console.log(err);
        throw err.response.data;
      });

    return fetchSuccessful;
  },
  updateUserProfile: ({ firstname, lastname, photoData }) => {
    const token = get()?.auth?.access;
    const userID = get()?.activeUser.id.toString();
    const { first_name, last_name, photo_url } = get()?.activeUser;

    const updateSuccessful = api
      .updateProfileData({
        token,
        userID,
        first_name: firstname ?? first_name,
        last_name: lastname ?? last_name,
        photo_url: photoData ?? photo_url,
      })
      .then((res) => {
        const { data } = res;
        // console.log("User profile updated successfully");
        return data;
      })
      .catch((err) => {
        // console.log("Error updating user profile" + err);
        throw err.response.data;
      });

    return updateSuccessful;
  },
  fetchPresignedUrl: (fileType) => {
    const token = get()?.auth?.access;
    const fetchSuccessful = api
      .fetchPresignedUrl({ token, name: "users", fileType })
      .then((res) => {
        const { data } = res;
        return data.urls[0];
      })
      .catch((err) => {
        // console.log(err);
        throw err.response.data;
      });

    return fetchSuccessful;
  },
  favouriteSession: (sessionID) => {
    const token = get()?.auth?.access;
    const fetchSuccessful = api
      .favouriteSession({ token, sessionID })
      .then((res) => {
        const { data } = res;
        return data;
      })
      .catch((err) => {
        // console.log(err);
        throw err.response.data;
      });

    return fetchSuccessful;
  },
});
