import React, { useState, lazy, useEffect } from "react";
import { Navigate, Route, Routes } from "react-router";
import { ROUTES } from "constant";
import useStore from "store";
import PublicLayout from "components/layout/PublicLayout";
import ProtectedLayout from "components/layout/ProtectedLayout";
import Landing from "pages/Landing";
import Home from "pages/Home";
import PageNotFound from "pages/PageNotFound";
import LoadLazy from "components/layout/LoadLazy";
import ProtectedRestricted from "components/layout/ProtectedRestricted";
import Stripe from "pages/Payments";
import Success from "pages/Payments/Checkout/Success";



// Public Layout Pages
const Login = lazy(() => import("pages/Login"));
const Signup = lazy(() => import("pages/Signup"));
const ForgotPassword = lazy(() => import("pages/ForgotPassword"));
const Verification = lazy(() => import("pages/Verification"));
const ResetPassword = lazy(() => import("pages/ResetPassword"));

// Protected Layout Pages
const Profile = lazy(() => import("pages/Profile"));
const ChangePassword = lazy(() => import("pages/ChangePassword"));
const Dashboard = lazy(() => import("pages/Dashboard"));
const TermsOfService = lazy(() => import("pages/TermsOfService"));
const PrivacyPolicy = lazy(() => import("pages/PrivacyPolicy"));
const FAQ = lazy(() => import("pages/FAQ"));
const ForYou = lazy(() => import("pages/Home/ForYou"));
const Browse = lazy(() => import("pages/Home/Browse"));
const Favourites = lazy(() => import("pages/Home/Favourites"));
const Category = lazy(() => import("pages/Category"));
const Subcategory = lazy(() => import("pages/Subcategory"));
const SessionDetail = lazy(() => import("pages/SessionDetail"));
const Module = lazy(() => import("pages/Module"));
const LessonTemplate = lazy(() => import("pages/Lesson"));
const ModuleDetail = lazy(() => import("pages/Module/ModuleDetail"));
const UpgradeOptions = lazy(() => import("pages/Payments/UpgradeOptions"));
const Checkout = lazy(() => import("pages/Payments/Checkout"));
const success = lazy(() => import("pages/Payments/Checkout/Success"));

const Router = () => {
  const store = useStore();
  const [routes, setRoutes] = useState(null);

  useEffect(() => {
    store
      .verifyAuthentication()
      .then(() => {
        if (Boolean(store.activeUser.verified || store.verified))
          setRoutes(protectedRoutes);
        else setRoutes(protectedRestrictedRoutes);
      })
      .catch(() => {
        setRoutes(publicRoutes);
      });
  }, [store.auth.access, store.verified]);

  const publicRoutes = (
    <Route path={ROUTES.landing} element={<PublicLayout />}>
      <Route index element={<Landing />} />
      <Route
        path={ROUTES.login}
        element={<LoadLazy component={<Login />} />}
      ></Route>
      <Route
        path={ROUTES.signup}
        element={<LoadLazy component={<Signup />} />}
      ></Route>
      <Route
        path={ROUTES.forgotPassword}
        element={<LoadLazy component={<ForgotPassword />} />}
      ></Route>
      <Route
        path={ROUTES.resetPassword}
        element={<LoadLazy component={<ResetPassword />} />}
      ></Route>
      {/* <Route path="/404" element={<PageNotFound />} /> */}
      <Route path="*" element={<Navigate to={ROUTES.landing} replace />} />
    </Route>
  );

  const protectedRestrictedRoutes = (
    <Route path={ROUTES.landing} element={<ProtectedRestricted />}>
      <Route index element={<Navigate to={ROUTES.verify} replace />} />
      <Route
        path={ROUTES.verify}
        element={<LoadLazy component={<Verification />} />}
      />
      {/* <Route path="*" element={<Navigate to={ROUTES.verify} replace />} /> */}

      <Route path="/404" element={<PageNotFound />} />
      <Route path="*" element={<PageNotFound />} />
    </Route>
  );

  const protectedRoutes = (
    <Route path={ROUTES.landing} element={<ProtectedLayout />}>
      <Route index element={<Navigate to={ROUTES.home} replace />} />
      <Route path={ROUTES.faq} element={<LoadLazy component={<FAQ />} />} />
      <Route
        path={ROUTES.privacyPolicy}
        element={<LoadLazy component={<PrivacyPolicy />} />}
      />
      <Route
        path={ROUTES.tos}
        element={<LoadLazy component={<TermsOfService />} />}
      />
      <Route path={ROUTES.home} element={<LoadLazy component={<Home />} />}>
        <Route index element={<Navigate to={ROUTES.browse} replace />}></Route>
        <Route path={"categories"}>
          <Route index element={<LoadLazy component={<Browse />} />} />
        </Route>
        <Route
          path={"favourites"}
          element={<LoadLazy component={<Favourites />} />}
        ></Route>
      </Route>
      <Route path="/category">
        <Route index element={<Navigate to={ROUTES.browse} replace />} />
        <Route
          path=":categoryID"
          element={<LoadLazy component={<Category />} />}
        ></Route>
        {/* Subcategory isn't nested because category doesn't have an outlet. */}
        <Route
          path=":categoryID/:subcategoryID"
          element={<LoadLazy component={<Subcategory />} />}
        ></Route>
      </Route>
      <Route path="/session">
        <Route index element={<Navigate to={ROUTES.browse} replace />} />
        <Route
          path=":sessionID"
          element={<LoadLazy component={<SessionDetail />} />}
        ></Route>
        {/* Module isn't nested because session doesn't have an outlet. */}
        <Route
          path=":sessionID/:moduleID"
          element={<LoadLazy component={<Module />} />}
        >
          <Route index element={<LoadLazy component={<ModuleDetail />} />} />
          <Route
            path=":lessonID"
            element={<LoadLazy component={<LessonTemplate />} />}
          />
        </Route>
      </Route>
      <Route
        path={ROUTES.pricing}
        element={<LoadLazy component={<UpgradeOptions />} />}
      ></Route>
      <Route
        path={ROUTES.checkout}
        element={<LoadLazy component={<Stripe />} />}
      ></Route>
      <Route
        path={ROUTES.success}
        element={<LoadLazy component={<Success />} />}
      ></Route>

      <Route
        path={ROUTES.profile}
        element={<LoadLazy component={<Profile />} />}
      ></Route>
      <Route
        path={ROUTES.changePassword}
        element={<LoadLazy component={<ChangePassword />} />}
      ></Route>
      <Route
        path={ROUTES.dashboard}
        element={<LoadLazy component={<Dashboard />} />}
      ></Route>
      <Route
        path={ROUTES.verify}
        element={<Navigate to={ROUTES.home} replace />}
      />

      <Route path="/404" element={<PageNotFound />} />
      <Route path="*" element={<PageNotFound />} />
    </Route>
  );

  return <Routes>{routes}</Routes>;
};

export default Router;
