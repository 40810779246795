import { authActions } from "./authActions";
import { activeActions } from "./activeActions";
import { userActions } from "./userActions";
import { subscribeAction } from "./subscribeAction";

export const Actions = (set, get) => ({
  ...authActions(set, get),
  ...userActions(set, get),
  ...activeActions(set, get),
  ...subscribeAction(set, get),
});
