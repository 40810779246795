import Loader from "components/shared/Loader";
import React from "react";
import { Outlet, useNavigate } from "react-router";
import useStore from "store";

const ProtectedRestricted = () => {
  const store = useStore();
  const navigate = useNavigate();

  return (
    <div style={{ height: "calc(100vh - 0px)", overflow: "clip" }}>
      {store.activeUser === null ? (
        <Loader />
      ) : Boolean(store?.verified) ? (
        <Loader />
      ) : (
        <Outlet />
      )}
    </div>
  );
};

export default ProtectedRestricted;
