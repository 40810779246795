import Loader from "components/shared/Loader";
import { ROUTES } from "constant";
import React, { useEffect } from "react";
import { Outlet, useNavigate, useParams } from "react-router";
import { toast } from "react-toastify";
import useStore from "store";

const ProtectedLayout = () => {
  const navigate = useNavigate();
  const params = useParams();
  const { categoryID, subcategoryID, sessionID, moduleID } = params;
  const store = useStore();

  useEffect(() => {
    if (
      store.auth.access === "" ||
      store.auth.refresh === "" ||
      store.activeUser === null
    ) {
      if (store.signOut()) navigate(ROUTES.landing, { replace: true });
    } else {
      store.verifyAuthentication().catch(() => {
        toast.warn("Unable to authorize user!");
        if (store.signOut()) navigate(ROUTES.landing, { replace: true });
      });
    }
  }, [store.auth.access]);

  useEffect(() => {
    if ((store.activeUser !== null && store.data.categories) === null) {
      store.fetchAllCategories();
    }
  }, [store.data.categories]);

  //Set Active Category
  useEffect(() => {
    if (store.data.categories !== null) {
      if (Boolean(categoryID) && Boolean(store?.data?.categories)) {
        const success = store.setActiveCategory(categoryID);
        if (success) {
          store.fetchAllSubcategories(categoryID);
        } else {
          navigate(ROUTES.browse, { replace: true });
        }
      } else {
        if (Boolean(sessionID) && store.active.category !== null) {
          store.setActiveCategory("NONE");
        }
      }
    }
  }, [store.data.categories, categoryID]);

  //Set Active Subcategory
  useEffect(() => {
    if (store.data.categories !== null) {
      if (
        Boolean(categoryID) &&
        Boolean(subcategoryID) &&
        Boolean(store?.data?.categories) &&
        Boolean(store?.data?.subcategories)
      ) {
        const success = store.setActiveSubcategory(subcategoryID);
        if (success) {
          store.fetchAllSessions({categoryID, subcategoryID});
        } else {
          navigate(`${ROUTES.browse}/${categoryID}`, { replace: true });
        }
      } else {
        if (Boolean(sessionID) && store.active.subcategoryID !== null) {
          store.setActiveSubcategory("NONE");
        }
      }
    }
  }, [
    store.data.categories,
    store?.data?.subcategories,
    categoryID,
    subcategoryID,
  ]);

  //Set Active Session
  useEffect(() => {
    if (store.data.categories !== null) {
      if (Boolean(sessionID)) {
        if (store.data.sessions === null) {
          store.fetchSessionByID(sessionID);
        } else {
          const success = store.setActiveSession(sessionID);
          if (success) {
            store.fetchAllModules(sessionID);
          } else {
            navigate(`${ROUTES.browse}`, { replace: true });
          }
        }
      } else {
        if (store.active.session !== null) {
          store.setActiveSession("NONE");
        }
      }
    }
  }, [store.data.categories, store.data.sessions, sessionID]);

  useEffect(() => {
    if (store.data.categories !== null) {
      if (Boolean(moduleID) && store.data.modules !== null) {
        const success = store.setActiveModule(moduleID);
        if (success) {
          store.fetchAllLessons(moduleID);
        } else {
          navigate(`${ROUTES.sessions}/${moduleID}`, { replace: true });
        }
      } else {
        if (store.active.module !== null) {
          store.setActiveModule("NONE");
        }
      }
    }
  }, [store.data.categories, store.data.modules, moduleID]);

  return (
    <div style={{ height: "calc(100vh - 0px)", overflow: "clip" }}>
      {store.activeUser === null ? <Loader /> : <Outlet />}
    </div>
  );
};

export default ProtectedLayout;
