import URL from "api/constants";
import user from "api/config";

export const getUserProfile = (token) =>
  user.get(URL.profile, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const getAllCategories = (token) =>
  user.get(URL.categories, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const getCategory = (token, id) =>
  user.get(URL.category(id), {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

/**
 *
 * @param {string} token
 * @param {number} categoryID
 * @returns
 */
export const getAllSubcategories = (token, categoryID) =>
  user.get(URL.subcategories, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params: {
      category_id: categoryID,
    },
  });

/**
 *
 * @param {string} token
 * @param {number} categoryID
 * @param {number} subcategoryID
 * @param {string} role
 * @returns
 */
export const getAllSessions = (token, categoryID, subcategoryID, favourite) =>
  user.get(URL.sessions, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params: {
      category_id: categoryID,
      subcategory_id: subcategoryID,
      favourite
    },
  });

export const getSessionByID = (token, sessionID, role) =>
  user.get(URL.session(sessionID), {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

/**
 *
 * @param {string} token
 * @param {string} sessionID
 * @returns
 */
export const getAllModules = (token, sessionID) =>
  user.get(URL.modules, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params: {
      session_id: sessionID,
    },
  });

/**
 *
 * @param {string} token
 * @param {string} moduleID
 * @returns
 */
export const getAllLessons = (token, moduleID) =>
  user.get(URL.lessons, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params: {
      module_id: moduleID,
    },
  });

export const updateProfileData = ({
  token,
  first_name,
  last_name,
  photo_url,
}) =>
  user.put(
    URL.profile,
    {
      user: {
        first_name,
        last_name,
        photo_url,
      },
    },
    { headers: { Authorization: `Bearer ${token}` } }
  );

export const favouriteSession = ({ token, sessionID }) =>
  user.post(URL.favouriteSession(sessionID), {}, {
    headers: { Authorization: `Bearer ${token}` },
  });

export const fetchPresignedUrl = ({ token, name, fileType }) =>
  user.patch(
    URL.presigned,
    {
      ext: [fileType],
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        name,
      },
    }
  );
