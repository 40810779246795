import React from "react";
import Img404 from "assets/images/thumbnails/thumnail404.svg";
import { Link } from "react-router-dom";
import { ROUTES } from "constant";
import GoBack from "components/shared/GoBack";

const PageNotFoundJSX = () => {
  return (
    <div className="main-cont-wrapper mm-without-header">
      <section className="site-section img-text-section mm-height">
        <div className="container mm-height">
          <div className="rm-flex-between">
            <div className="row">
              <div className="col-md-6 col-lg-4 mx-auto">
                <article className="mm-article mb-4">
                  <GoBack to={ROUTES.landing} text="Home" />
                </article>
                <div className="minmind-card-wrapper">
                  <figure className="mm-card-fig text-center">
                    <img src={Img404} alt="404 Error" />
                  </figure>

                  <article className="mm-article mb-4">
                    <h1 className="ft-weight-700 font-sf-ui-display mm-text-primary text-center  text">
                      Page Not Found
                    </h1>
                  </article>
                  <article className="mm-article mb-4">
                    <h6 className="ft-weight-500 font-sf-ui-display text-center mm-text-secondary1">
                      We're sorry the page you request could not be found.
                      Please return to home.
                    </h6>
                  </article>
                  <div className="mm-btn-wrapper text-center ">
                    <Link
                      to={ROUTES.landing}
                      role="button"
                      type="submit"
                      className="theme_btn theme_primary theme_btn_lg "
                    >
                      Go Home
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default PageNotFoundJSX;
