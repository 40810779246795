import * as api from "api/auth";
import produce from "immer";

import { toast } from "react-toastify";
import { initialState } from "store";
import { deepClone } from "utility";

export const authActions = (set, get) => ({
  signIn: (user) => {
    const loadingToast = toast.loading(`Signing in User (${user?.email})...`);
    const response = api
      .signIn(user)
      .then((res) => {
        const data = res.data;
        toast.update(loadingToast, {
          render: "Sign in successful!",
          type: "success",
          isLoading: false,
          icon: "✅",
          autoClose: "1500",
        });
        set(
          {
            auth: {
              refresh: data?.refreshToken,
              access: data?.accessToken,
            },
            activeUser: { ...data.user },
          },
          false,
          `Set active user [${data?.user?.email}]/Update access token`
        );
        return data;
      })
      .catch((err) => {
        const message = err?.response?.data?.message;
        toast.update(loadingToast, {
          render: `Error signing in user: ${message}!`,
          type: "error",
          isLoading: false,
          icon: "🛑",
          autoClose: "2500",
        });
      });
    return response;
  },
  signUp: (user) => {
    const loadingToast = toast.loading(`Signing up User (${user?.email})...`);
    const response = api
      .signUp(user)
      .then((res) => {
        const data = res.data;
        toast.update(loadingToast, {
          render: "Sign up successful!",
          type: "success",
          isLoading: false,
          icon: "✅",
          autoClose: "1500",
        });
        set(
          {
            auth: {
              refresh: data?.refreshToken,
              access: data?.accessToken,
            },
            activeUser: { ...data.user },
          },
          false,
          `Set active user [${data?.user?.email}]/Update access token`
        );
        return data;
      })
      .catch((err) => {
        const message = err?.response?.data?.message;
        toast.update(loadingToast, {
          render: `Error signing up user: ${message}!`,
          type: "error",
          isLoading: false,
          icon: "🛑",
          autoClose: "2500",
        });
      });
    return response;
  },
  signOut: () => {
    set(
      deepClone(initialState),
      false,
      `LOGOUT//Clearing active user, auth tokens & data`
    );

    return true;
  },
  verifyUser: (otp) => {
    const token = get()?.auth?.access;
    const user = get().activeUser;
    const loadingToast = toast.loading(`Verifying User (${user?.email})...`);
    const response = api
      .verify(token, otp)
      .then((res) => {
        const data = res.data;
        toast.update(loadingToast, {
          render: "Verification successful!",
          type: "success",
          isLoading: false,
          icon: "✅",
          autoClose: "1500",
        });
        set(
          produce((state) => {
            state.verified = true;
          }),
          false,
          "Set verification state true"
        );
        get()?.getUserProfile();
        return data;
      })
      .catch((err) => {
        const message = err?.response?.data?.message;
        // console.log("Error verifying user", err);
        toast.update(loadingToast, {
          render: `Error verifying user: ${message}!`,
          type: "error",
          isLoading: false,
          icon: "🛑",
          autoClose: "2500",
        });
      });
    return response;
  },
  resendCode: () => {
    const token = get()?.auth?.access;
    const user = get().activeUser;
    const loadingToast = toast.loading(
      `Sending code to email (${user?.email})...`
    );

    const response = api
      .resend(token, user?.id)
      .then((res) => {
        const data = res.data;
        toast.update(loadingToast, {
          render: `New code sent to your email (${user?.email}) successfully!`,
          type: "success",
          isLoading: false,
          icon: "✅",
          autoClose: "1500",
        });

        return data;
      })
      .catch((err) => {
        const message = err?.response?.data?.message;
        toast.update(loadingToast, {
          render: `Error sending code: ${message}!`,
          type: "error",
          isLoading: false,
          icon: "🛑",
          autoClose: "2500",
        });
      });
    return response;
  },
  forgotPassword: (email) => {
    const loadingToast = toast.loading(`Sending token to email (${email})...`);

    const response = api
      .forgotPassword(email)
      .then((res) => {
        const data = res.data;
        toast.update(loadingToast, {
          render: `Reset token sent to your email (${email}) successfully!`,
          type: "success",
          isLoading: false,
          icon: "✅",
          autoClose: "1500",
        });

        return data;
      })
      .catch((err) => {
        const message = err?.response?.data?.message;
        toast.update(loadingToast, {
          render: `Error sending token: ${message}!`,
          type: "error",
          isLoading: false,
          icon: "🛑",
          autoClose: "2500",
        });
      });
    return response;
  },
  resetPassword: (resetToken, newPassword) => {
    const loadingToast = toast.loading(`Resetting password...`);
    const response = api
      .resetPassword(resetToken, newPassword)
      .then((res) => {
        const data = res.data;
        toast.update(loadingToast, {
          render: `Password reset successfully!`,
          type: "success",
          isLoading: false,
          icon: "✅",
          autoClose: "1500",
        });

        return data;
      })
      .catch((err) => {
        const message = err?.response?.data?.message;
        toast.update(loadingToast, {
          render: `Unable to reset password: ${message}!`,
          type: "error",
          isLoading: false,
          icon: "🛑",
          autoClose: "2500",
        });
      });
    return response;
  },
  changePassword: (currentPassword, newPassword) => {
    const token = get().auth.access;
    const email = get().activeUser.email;

    const loadingToast = toast.loading(`Changing password...`);
    const response = api
      .changePassword(token, email, currentPassword, newPassword)
      .then((res) => {
        const data = res.data;
        toast.update(loadingToast, {
          render: `Password changed successfully!`,
          type: "success",
          isLoading: false,
          icon: "✅",
          autoClose: "1500",
        });

        return data;
      })
      .catch((err) => {
        const message = err?.response?.data?.message;
        toast.update(loadingToast, {
          render: `Unable to change password: ${message}!`,
          type: "error",
          isLoading: false,
          icon: "🛑",
          autoClose: "2500",
        });
      });
    return response;
  },
  refreshUser: () => {
    const refreshToken = get()?.auth?.refresh;
    // console.log("Refresh toke:", refreshToken);
    const refreshSuccessful = api
      .refreshUser(refreshToken)
      .then((res) => {
        const data = res.data;
        set(
          {
            auth: {
              refresh: data?.refreshToken,
              access: data?.accessToken,
            },
            activeUser: { ...data.user },
          },
          false,
          `Refresh active user [${data?.user?.email}]/Update access token`
        );
        return true;
      })
      .catch((err) => {
        throw err?.response?.data;
      });
    return refreshSuccessful;
  },
  verifyAuthentication: async () => {
    // const { access, refresh } = get()?.auth;
    const success = await get()
      ?.getUserProfile()
      .then((res) => {
        // console.log("Userprofile: ", res);
        return true;
      })
      .catch((err) => {
        // console.log("Error: ", err);
        if (err.code === 401) {
          const refreshSuccessful = get()
            ?.refreshUser()
            .then((res) => {
              // console.log("Refreshing user", res);
            })
            .catch((err) => {
              // console.log("Unable to refresh user. Please sign out!", err);
              throw new Error("Unable to refresh user. Please sign out!");
            });

          return refreshSuccessful;
        }
      });
    return success;
  },
});
