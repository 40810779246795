import React from "react";
import { Link, NavLink } from "react-router-dom";
import NavClose from "assets/images/icons/caret-left-square.svg";
import Download from "assets/images/icons/box-arrow-down.svg";
import Logo from "assets/images/thumbnails/brand-logo.svg";
import { ROUTES } from "constant";
import { getFileUrl } from "utility";
import TextAvatar from "components/shared/TextAvatar";

const HeaderJSX = (props) => {
  return (
    <header className="main_header">
      <nav
        className={`theme_navigation ${
          props.navState ? "-open" : "-closed"
        } navbar navbar-expand-lg`}
      >
        <Link className="logo d-none d-lg-block" to={ROUTES.home}>
          <img src={Logo} alt="MinMind Logo" className="header__logo" />
        </Link>

        <div className="d-flex d-lg-none w-100">
          <div className="me-auto">
            <span
              id="toggle-sidebar"
              className="navigation-toggler"
              onClick={props.toggleNavbar}
            >
              <span className="sr-only">Toggle Sidebar</span>
              <span className="icon-bar"></span>
              <span className="icon-bar"></span>
              <span className="icon-bar"></span>
            </span>
          </div>
          {props.allowPrompt ? (
            <div className="py-3">
              <button
                className="d-flex d-lg-none align-items-center btn btn-sm ps-3 pe-4 btn-primary rounded-pill "
                onClick={props.installPrompt}
              >
                <img
                  src={Download}
                  alt="download and install"
                  className="pe-1"
                />
                Install
              </button>
            </div>
          ) : (
            ""
          )}
        </div>
        <div
          className={`main_menu_wrap ${props.large ? "" : "shadow-lg"}`}
          style={props.large ? null : { overflow: "auto" }}
        >
          <div className="mm-profile-details-wrapper d-block d-lg-none">
            <div className="text-end cursor-pointer">
              <button className="btn p-0" onClick={props.toggleNavbar}>
                <img src={NavClose} alt="closes sidebar" width="24px" />
              </button>
            </div>
            <div className="mm-profile-wrapper">
              {Boolean(props?.user?.photo_url) ? (
                <span className="mm-profile">
                  <img
                    src={getFileUrl(props.user?.photo_url)}
                    alt="User's Avatar"
                  />
                </span>
              ) : (
                <span
                  className="mm-profile"
                  style={{ backgroundColor: "transparent" }}
                >
                  <TextAvatar
                    size={74}
                    text={`${props.user?.first_name.toUpperCase()[0]}${
                      props.user?.last_name.toUpperCase()[0]
                    }`}
                  />
                </span>
              )}
              {Boolean(props.user?.verified) && (
                <button
                  className="btn btn-outline-light btn-sm mx-3 rounded"
                  onClick={props.editProfile}
                >
                  Edit Profile
                </button>
              )}
            </div>
            <div className="mm-profile-cont-wrapper">
              <h4 className="ft-weight-700 font-sf-ui-display text-white text-capitalize">
                {`${props.user?.first_name} ${props.user?.last_name}`}
              </h4>
              <p className="text-white text-capitalize mb-1 ">
                {props.user?.role ?? ""}
              </p>
              {props.user?.email ?? ""}
            </div>
          </div>
          {props.allowPrompt ? (
            <button
              className="d-none d-lg-flex align-items-center btn btn-sm me-3 ps-3 pe-4 btn-primary rounded-pill "
              onClick={props.installPrompt}
            >
              <img src={Download} alt="download and install" className="pe-1" />
              Install
            </button>
          ) : (
            ""
          )}
          <ul className="navbar-nav main_menu ">
            {Boolean(props.user?.verified) &&
              props.navlinks?.map((tab, index) => {
                return (
                  <li className="nav-item" key={index}>
                    <NavLink
                      className={`nav-link ${({ isActive }) =>
                        isActive ? "active" : ""}`}
                      to={tab.link}
                      replace
                    >
                      {tab.name}
                    </NavLink>
                  </li>
                );
              })}
            <button
              className="d-flex d-lg-none btn btn-outline-dark btn-sm fw-bold px-3 mt-2 mt-lg-0 w-75 justify-content-center"
              onClick={props.logout}
            >
              Logout
            </button>
            <div className="position-relative">
              <button
                className=" d-none d-lg-flex btn border-0 btn fw-bold px-3 mt-0 w-100 justify-content-center rounded-circle"
                onClick={() => props.setProfileDropdown(!props.profileDropdown)}
                title="View Profile"
              >
                {Boolean(props.user?.photo_url) ? (
                  <img
                    src={getFileUrl(props.user?.photo_url)}
                    alt="User"
                    width="55px"
                    height="55px"
                    style={{ borderRadius: "50%" }}
                    className="shadow-sm"
                  />
                ) : (
                  <TextAvatar
                    size={55}
                    text={`${props.user?.first_name.toUpperCase()[0]}${
                      props.user?.last_name.toUpperCase()[0]
                    }`}
                  />
                )}
              </button>
              <div
                className={`position-absolute bg-light rounded-3 shadow p-3 text-center ${
                  !props.profileDropdown ? "d-none" : ""
                }`}
                style={{
                  width: "200px",
                  position: "absolute",
                  top: "65px",
                  right: "0px",
                }}
              >
                <ul>
                  <li className="mb-2 text-dark text-capitalize">
                    <span className="fw-bold">
                      {`${props.user?.first_name} ${props.user?.last_name}`}
                    </span>
                    <br />
                    {props.user?.role}
                  </li>
                  <hr className="mt-0" />
                  {Boolean(props.user?.verified) && (
                    <li className="mb-2 text-dark">
                      <Link to={ROUTES.profile} className="text-reset">
                        Profile
                      </Link>
                    </li>
                  )}
                  <li className="mb-2">
                    <button
                      className="d-flex btn d-slg-none fw-bold px-3 mt-2 mt-lg-0 w-100 justify-content-center"
                      onClick={props.logout}
                    >
                      Logout
                    </button>
                  </li>
                </ul>
              </div>
            </div>
          </ul>
        </div>
        <div className="mm-header-menu-wrapper-overlay"></div>
      </nav>
    </header>
  );
};

export default HeaderJSX;
