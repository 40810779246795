import produce from "immer";
import { initialState } from "store";

export const activeActions = (set, get) => ({
  resetState: () => {
    set(
      {
        ...initialState,
      },
      true,
      "Resetting state."
    );
  },
  /**
   * Pass the categoryID as string to set the category as active. Pass nothing to reset the active category.
   * @param {string} categoryID
   */
  setActiveCategory: (categoryID) => {
    let currentCategory = get()?.data?.categories?.find(
      (c) => c.id.toString() === categoryID
    );
    if (Boolean(currentCategory)) {
      set(
        produce((state) => {
          state.active.category = currentCategory;
        }),
        false,
        `Set active Category [${categoryID}]`
      );
      return true;
    } else {
      set(
        produce((state) => {
          state.active.category = null;
        }),
        false,
        `Category ID [${categoryID}] invalid/Reset active category`
      );
      return false;
    }
  },
  /**
   * Pass the subcategoryID as string to set the subcategory as active. Pass nothing to reset the active subcategory.
   * @param {string} subcategoryID
   */
  setActiveSubcategory: (subcategoryID) => {
    let currentSubcategory = get()?.data?.subcategories?.find(
      (s) => subcategoryID === s.id.toString()
    );
    if (Boolean(currentSubcategory)) {
      // console.log("Current Subcategory", currentSubcategory);
      set(
        produce((state) => {
          state.active.subcategory = currentSubcategory;
        }),
        false,
        `Set active Subcategory [${subcategoryID}]`
      );
      return true;
    } else {
      set(
        produce((state) => {
          state.active.subcategory = null;
        }),
        false,
        `Subcategory ID [${subcategoryID}] invalid/Reset active subcategory`
      );
      return false;
    }
  },
  /**
   * Pass the sessionID as string to set the session as active. Pass nothing to reset the active session.
   * @param {string} sessionID
   */
  setActiveSession: (sessionID) => {
    let currentSession = get()?.data?.sessions?.find(
      (s) => sessionID === s.id.toString()
    );
    if (Boolean(currentSession)) {
      // // console.log("Current Session", currentSession);
      const { category_id: categoryID, sub_category_id: subcategoryID } =
        currentSession;
      const currentCategory = get()?.data?.categories?.find(
        (c) => c.id.toString() === categoryID
      );
      const currentSubcategory = currentCategory.sub_categories?.find(
        (s) => subcategoryID === s.id.toString()
      );
      set(
        produce((state) => {
          state.active.category = currentCategory;
          state.active.subcategory = currentSubcategory;
          state.active.session = currentSession;
        }),
        false,
        `Set active Session [${sessionID}] (with active Category[${categoryID}] & Subcategory[${subcategoryID}])`
      );
      return true;
    } else {
      set(
        produce((state) => {
          state.active.session = null;
        }),
        false,
        `Session ID [${sessionID}] invalid/Reset active session`
      );
      return false;
    }
  },
  /**
   * Pass the moduleID as string to set the module as active. Pass nothing to reset the active module.
   * @param {string} moduleID
   */
  setActiveModule: (moduleID) => {
    let currentModule = get()?.data?.modules?.find(
      (m) => moduleID === m.id.toString()
    );
    if (Boolean(currentModule)) {
      // // console.log("Current Module", currentModule);
      set(
        produce((state) => {
          state.active.module = currentModule;
        }),
        false,
        `Set active Module [${moduleID}]`
      );
      return true;
    } else {
      set(
        produce((state) => {
          state.active.module = null;
        }),
        false,
        `Module ID [${moduleID}] invalid/Reset active module`
      );
      return false;
    }
  },
  /**
   * Pass the lesson as object to set it active. Pass nothing to reset the active lesson.
   * @param {object} lessonID
   */
  setActiveLesson: (lessonID) => {
    let currentLesson = get()?.data?.lessons?.find(
      (l) => lessonID === l.id.toString()
    );
    // // console.log("Current Lesson", currentLesson);
    if (Boolean(currentLesson)) {
      // // console.log("Current Lesson", currentLesson);
      set(
        produce((state) => {
          state.active.lesson = currentLesson;
        }),
        false,
        `Set active Lesson [${lessonID}]`
      );
      return true;
    } else {
      set(
        produce((state) => {
          state.active.lesson = null;
        }),
        false,
        `Lesson ID [${lessonID}] invalid/Reset active lesson`
      );
      return false;
    }
  },
});
