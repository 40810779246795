import React from "react";
import "./index.css";

const Loader = () => {
  return (
    <div className="loader-spinner">
      <svg viewBox="0 0 120 120">
        <circle className="load one" cx="60" cy="60" r="20" pathLength="1" />
        <circle className="load two" cx="60" cy="60" r="10" />
        <circle className="load three" cx="60" cy="60" r="30" pathLength="1" />
      </svg>
    </div>
  );
};

export default Loader;
