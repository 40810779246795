import { ROUTES } from "constant";
import React from "react";
import { useNavigate } from "react-router";
import LandingJSX from "./Landing";

const Landing = () => {
  const navigate = useNavigate();

  const headToLogin = () => {
    navigate(ROUTES.login, { replace: true });
  };

  return <LandingJSX handleLogin={headToLogin} />;
};

export default Landing;
