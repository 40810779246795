import { ROUTES } from "constant";
import React from "react";
import { Link } from "react-router-dom";

const LandingJSX = ({ handleLogin }) => {
  return (
    <div className="main-cont-wrapper main-cont-hero-wrapper main-cont-hero-wrapper-v2">
      <section className="hero-section site-section">
        <div className="container">
          <div className="rm-flex-between">
            <div className="rm-blank-wrapper"></div>
            <article className="mm-article text-center">
              <h1 className="display-1 ft-weight-700">MinMind</h1>
              <h2 className="ft-weight-400 font-josefin-sans">
                Do meditation. Stay focused. <br /> Live a healthy life.
              </h2>
            </article>
            <div className="mm-bttom-wrapper">
              <div className="mm-btn-wrapper">
                <button
                  className="theme_btn theme_primary d-block"
                  onClick={handleLogin}
                >
                  Login
                </button>
              </div>
              <div className="form-link-wrapper">
                Don't have an account?{" "}
                <Link
                  to={ROUTES.signup}
                  className="mm-link link-effect ft-weight-600"
                >
                  Sign Up
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default LandingJSX;
