import Loader from "components/shared/Loader";
import { ROUTES } from "constant";
import React, { useEffect, useMemo } from "react";
import { Outlet, useNavigate } from "react-router";
import useStore from "store";

const PublicLayout = () => {
  const navigate = useNavigate();
  const store = useStore();

  const authValid = useMemo(
    () => store.verifyAuthentication(),
    [store.auth.access]
  );

  useEffect(() => {
    if (authValid) {
      Boolean(store?.activeUser?.verified)
        ? navigate(ROUTES.home, { replace: true })
        : navigate(ROUTES.verify, { replace: true });
    }
  }, [store.auth.access, store.activeUser]);

  return (
    <div className="site-wrapper" style={{ height: "100vh" }}>
      {store.activeUser === null ? <Outlet /> : <Loader />}
    </div>
  );
};

export default PublicLayout;
