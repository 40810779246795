import { BrowserRouter } from "react-router-dom";
import Router from "router";
// import "assets/css/main.css";
import { ToastContainer, Bounce } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function App() {
  return (
    <BrowserRouter>
      <ToastContainer
        limit={1}
        draggable
        draggableDirection="x"
        draggablePercent={60}
        position={"top-right"}
        transition={Bounce}
        theme="colored"
        autoClose={1500}
        hideProgressBar={false}
        pauseOnFocusLoss={false}
      />
      <Router />
    </BrowserRouter>
  );
}

export default App;
