import { LOCAL_STORAGE_KEY } from "constant";
import create from "zustand";
import { devtools, persist } from "zustand/middleware";
import { Actions } from "./actions";

export const initialState = {
  auth: {
    access: "",
    refresh: "",
  },
  data: {
    categories: null,
    subcategories: null,
    sessions: null,
    modules: null,
    lessons: null,
  },
  active: {
    category: null,
    subcategory: null,
    session: null,
    module: null,
    lesson: null,
  },
  activeUser: null,
  verified: false,
  plans: null,
  planData: null,
  cards: null,
  card: null,
  subscription:null
};

const useStore = create(
  devtools(
    persist(
      (set, get) => ({
        ...initialState,
        ...Actions(set, get),
      }),

      {
        name: LOCAL_STORAGE_KEY,
        storage: () => localStorage,
        partialize: (state) => ({
          auth: state.auth,
          activeUser: state.activeUser,
        }),
      }
    )
  )
);

export default useStore;
