import React from "react";
import Header from "components/layout/Header";

const WithHeader = ({ children }) => {
  return (
    <>
      <Header />
      <div
        className="main-cont-wrapper mm-gradient"
        style={{ overflow: "hidden", scrollbarGutter: "stable both-edges" }}
      >
        <section className="site-section">
          <div className="container mm-height">
            <div className="row mm-height">
              <div className="col-lg-6 mx-auto">{children}</div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default WithHeader;
