import URL from "api/constants";
import subscribe from "api/config";

export const GetStripPlans = (token) =>
  subscribe.get(URL.getStripeUrl, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
   
  });

export const GetStripCards = (token, id) =>
  subscribe.get(URL.getStripeCardUrl, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params: {
      id: id,
    }
  });

export const CreateSubscription = (token, body) =>
  subscribe.post(URL.createSubscriptionUrl, body, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const AddStripeCard = (token, card_token) => 
  subscribe.post(URL.addStripeCardUrl, {
    "card_token": card_token
}, {
    headers: {
      Authorization: `Bearer ${token}`
    },  
  });
