import * as api from "api/subscriptionApis";
import produce from "immer";
import { toast } from "react-toastify";

export const subscribeAction = (set, get) => ({
  getSubscribe: (plan) => {
    const token = get()?.auth?.access;
    const GetSubscribePlans = api
      .GetStripPlans(token)
      .then((res) => {
        const { data } = res;
        set(
          produce((state) => {
            state.plans = data?.plans;
            state.planData = plan;
            
          }),
          false,
          `Fetched Plans `
        );
        return true;
      })
      .catch((err) => {
        toast.error("Unable to fetch Plans at the moment.");
        return false;
      });

    return GetSubscribePlans;
  },

  getCards: () => {
    const token = get()?.auth?.access;
    const GetStripeCards = api
      .GetStripCards(token)
      .then((res) => {
        const { data } = res;
        set(
          produce((state) => {
            state.cards = data?.cards;
          }),
          false,
          `Fetched Cards `
        );
        return true;
      })
      .catch((err) => {
        toast.error("Unable to fetch Cards at the moment.");
        return false;
      });

    return GetStripeCards;
  },

  AddCards: (card_token) => {
    const token = get()?.auth?.access;
    const AddStripeCards = api
      .AddStripeCard(token,card_token)
      .then((res) => {
        const { data } = res;
        set(
          produce((state) => {
            // state.data.cards = data?.cards
          }),
          false,
          `Fetched Cards `
        );
        return true;
      })
      .catch((err) => {
        toast.error("Unable to fetch Cards at the moment.");
        return false;
      });

    return AddStripeCards;
  },

  Createsubscribe: (body) => {
    const token = get()?.auth?.access;
    const loadingToast = toast.loading("Processing payment...");
    const Subscription = api
      .CreateSubscription(token, body)
      .then((res) => {
        const { data } = res;
        toast.update(loadingToast, {
          render: "Purchase successful!",
          type: "success",
          isLoading: false,
          icon: "✅",
          autoClose: "1500",
        });
        set(
          produce((state) => {
            // state.data.cards = data?.cards
            state.subscription = data?.subscription;
          }),
          false,
          `Fetched Cards `
        );
        return true;
      })
      .catch((err) => {
        toast.error("Unable to fetch Cards at the moment.");
        return false;
      });

    return Subscription;
  },
});
