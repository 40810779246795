import LessonDataContainer from "components/layout/ListContainer/ListContainer";
import WithHeader from "components/layout/WithHeader";
import React from "react";
import { Outlet } from "react-router";
import { NavLink } from "react-router-dom";

const HomeJSX = (props) => {
  return (
    <WithHeader>
      <article className="mm-article">
        <h1 className="ft-weight-700 font-sf-ui-display mm-text-primary">
          Welcome back, {props?.user?.first_name}!
        </h1>
        <h6 className="ft-weight-500">How are you feeling today ?</h6>
      </article>
      <div className="minmind-tabs-wrapper minmind-browse-wrap ">
        <ul
          className="nav nav-tabs nav-tabs-wb browse-tabs d-flex justify-content-around"
          role="tablist"
        >
          {props.tabs.map((tab, index) => (
            <li key={index} className="nav-item" role="presentation">
              <NavLink
                to={tab.path}
                replace
                end
                className={`nav-link minmind-tabs ${({ isActive }) =>
                  isActive ? "" : "active browse-active"}`}
                type="button"
                role="tab"
              >
                {tab.name}
              </NavLink>
            </li>
          ))}
        </ul>
        <div className="tab-content mm-tab-content" id="myTabContent">
          <LessonDataContainer>
            <Outlet />
          </LessonDataContainer>
        </div>
      </div>
    </WithHeader>
  );
};

export default HomeJSX;
