import React from "react";

const TextAvatar = ({ size = 64, text, bgColor, textColor }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width={`${size}px`}
      height={`${size}px`}
      viewBox={`0 0 ${size} ${size}`}
      version="1.1"
    >
      <circle
        fill={bgColor ?? "#FF5733"}
        width={size}
        height={size}
        cx={size / 2}
        cy={size / 2}
        r={size / 2}
        className="shadow-sm"
      />
      <text
        x="50%"
        y="50%"
        style={{
          color: "#ffffff",
          lineHeight: "1",
          fontFamily:
            "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif",
        }}
        alignmentBaseline="middle"
        textAnchor="middle"
        fontSize={size * 0.4}
        fontWeight="400"
        dy=".1em"
        dominantBaseline="middle"
        fill={textColor ?? "#ffffff"}
      >
        {text ?? ""}
      </text>
    </svg>
  );
};

export default TextAvatar;
