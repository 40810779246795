import { ROUTES } from "constant";
import useInstallPrompt from "hooks/useInstallPrompt";
import useOutsideAlerter from "hooks/useOutsideAlerter";
import React, { useCallback, useRef, useState } from "react";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import useStore from "store";
import HeaderJSX from "./Header";

const Header = () => {
  const navigate = useNavigate();
  const wrapperRef = useRef(null);
  const [navState, setNavState] = useState(false);
  const [installPrompt, allowPrompt] = useInstallPrompt();
  const [profileDropdown, setProfileDropdown] = useState(false);

  const toggleNavbar = () => {
    setNavState(!navState);
  };

  useOutsideAlerter(wrapperRef, () => {
    setNavState(false);
    setProfileDropdown(false);
  });

  const tabs = [
    { name: "Home", link: ROUTES.home },
    { name: "Dashboard", link: ROUTES.dashboard },
    { name: "FAQs", link: ROUTES.faq },
    { name: "Privacy Policy", link: ROUTES.privacyPolicy },
    { name: "Terms of Service", link: ROUTES.tos },
    { name: "Subscribe", link: ROUTES.pricing },

  ];

  const user = useStore((state) => state.activeUser);
  const signOut = useStore((state) => state.signOut);

  const logout = useCallback(() => {
    if (signOut()) {
      navigate(ROUTES.landing);
      toast.success("Sign out successful!", {
        icon: "✅",
      });
    }
  }, [signOut]);

  const componentProps = {
    navlinks: tabs,
    user,
    logout,
    navState,
    toggleNavbar,
    allowPrompt,
    installPrompt,
    profileDropdown,
    setProfileDropdown,
    large: window.screen.width > 991,
    editProfile: () => navigate(ROUTES.profile, { replace: true }),
  };

  return (
    <div ref={wrapperRef}>
      <HeaderJSX {...componentProps} />;
    </div>
  );
};

export default Header;
