import React from "react";
import { Link } from "react-router-dom";
import BackArrow from "assets/images/icons/icn-arrow-left.svg";

const GoBack = ({ to, text }) => {
  return (
    <Link to={to ?? -1} replace className="back-link-wrapper">
      <img src={BackArrow} alt="Back arrow" /> {text ?? "Back"}
    </Link>
  );
};

export default GoBack;
