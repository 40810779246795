import WithoutHeader from "components/layout/WithoutHeader";
import GoBack from "components/shared/GoBack";
import tic from "assets/images/icons/icn-check.svg";
import {
  useStripe,
  useElements,
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
} from "@stripe/react-stripe-js";
import useStore from "store";

const CardComponents = ({ setNewCard }) => {
  const store = useStore();
  const stripe = useStripe();
  const elements = useElements();
  const handleAddCard = async (e) => {
    e.preventDefault();
    const cardDetails = elements.getElement(CardNumberElement);
    const stripeToken = await stripe
      .createToken(cardDetails)
      .then((payload) => payload?.token?.id);
    const res = await store.AddCards(stripeToken);
    if (res) {
      setNewCard(false);
    store.getCards();
    }
  };
  const cardElementOptions = {
    style: {
      base: {
        showIcon: true,
        color: "#666",
        fontSize: "18px",
      },
      invalid: {
        color: "#fa755a",
        fontSize: "18px",
      }
    }
  }
  return (
    <WithoutHeader>
      <div>
        <GoBack />
        <form
          className="mm-form mm-subscription-plan mm-form-subscription-plan"
          onSubmit={(e) => handleAddCard(e)}
        >
          <div className="subscription-list-wrapper">
            <article className="mm-article">
              <h6 className="mm-text-primary ft-weight-700 font-sf-ui-display">
                Card Details
              </h6>
            </article>
            <div className="card card-plan payment-card">
              <div className="card-body">
                <div className="rm-flex-row-between">
                  <label
                    for="state"
                    className="label_modify ft-weight-600 mb-0 "
                  >
                    Card Number
                    <img src={tic} alt="icon check" className="ms-2" />
                  </label>
                  {/* <a
                    href="#"
                    className="mm-form-link ft-weight-600 font-sf-ui-display text-gray"
                  >
                    Remove
                  </a> */}
                </div>
                <div className="input-group mm-credit-wrapper">
                  <div className="input-group-prepend">
                    {/* <span className="input-group-text">
                      <img
                        src="assets/images/icons/icn-metro-visa.png"
                        alt="icon check"
                      />
                    </span> */}
                  </div>
                  <CardNumberElement className="form-control input_modify" options={cardElementOptions}/>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-6">
              <div className="mm-form-group">
                <label
                  for="expiry"
                  className="label_modify ft-weight-600 font-sf-ui-display"
                >
                  Expiry Date
                </label>
                <CardExpiryElement className="input_modify font-sf-ui-display" options={cardElementOptions}/>
              </div>
            </div>
            <div className="col-6">
              <div className="mm-form-group">
                <label
                  for="CVV"
                  className="label_modify ft-weight-600 font-sf-ui-display"
                >
                  CVV
                </label>
                <CardCvcElement className="input_modify font-sf-ui-display" options={cardElementOptions}/>
              </div>
            </div>
          </div>
          {/* <div className="mm-form-group">
            <label className="check-cont ft-weight-600">
              Save as default
              <input type="checkbox" checked="checked" />
              <span className="checkbox-check"></span>
            </label>
          </div> */}

          <div className="mm-btn-wrapper text-center">
            <button type="submit" className="theme_btn theme_primary w-100">
              Add Card
            </button>
          </div>
        </form>
      </div>
    </WithoutHeader>
  );
};

export default CardComponents;
