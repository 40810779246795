import styles from "./ListContainer.module.css";

const LessonDataContainer = ({ children, gap, singleEdge }) => {
  return (
    <div
      id="lesson-data-container"
      className={styles.innerShadow}
      style={{
        height: `calc(100vh - ${gap ?? 200}px)`,
        maxHeight: `calc(100vh - ${gap ?? 200}px)`,
        overflowX: "hidden",
        overflowY: "auto",

        padding: "10px",
        paddingBottom: "20px",
        marginBottom: "10px",

        scrollbarColor: "#174e4d transparent",
        scrollbarWidth: "thin",
        scrollbarGutter: `stable ${singleEdge ? "" : "both-edges"}`,
      }}
    >
      <div>{children}</div>
    </div>
  );
};

export default LessonDataContainer;
