import React, { useEffect, useState } from "react";
import WithoutHeader from "components/layout/WithoutHeader";
import GoBack from "components/shared/GoBack";
import { ElementsConsumer } from "@stripe/react-stripe-js";
import useStore from "store";
import CardComponents from "../AddNewCard";
import { useNavigate, useParams } from "react-router";

const Checkout = () => {
  const store = useStore();
  let navigate = useNavigate();
  const { cards } = store;

  let params = useParams();
  const [newCard, setNewCard] = useState(false);
  
  const defaultCard = {
    platform: "web",
    product_id: "",
    default_source:""
  };
  
  const [cardPrice, setCardPrice] = useState(defaultCard);
  const [cardData, setCardData] = useState();
  const { plans } = store;

  useEffect(() => {
    store.getSubscribe();
    store.getCards();
  }, []);

  const selectedPlan = plans?.find((plan) => plan.id === params.id);

  useEffect(() => {
    setCardPrice({ ...cardPrice, product_id: selectedPlan?.id, default_source: cardData?.id });
  }, [selectedPlan, cardData]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const res = await store.Createsubscribe(cardPrice);
      if (res === true) {
        navigate("/success");
      }
    } catch (error) {
      console.log("Error", error);
    }
  };

  const addCard = () => {
    setNewCard(true);
  };

  const handleCardChange = (card) => {
    setCardData(card);
  };

  return (
    <>
      {!newCard ? (
        <WithoutHeader>
          <article className="mm-article ">
            <GoBack />
            <h1 className="ft-weight-700 font-sf-ui-display mm-text-primary display-4 mb-3">
              Subscription Plan
            </h1>
          </article>

          <form
            className="mm-form mm-subscription-plan mm-form-subscription-plan"
            onSubmit={handleSubmit}
          >
            <div className="subscription-list-wrapper">
              <div className="card card-plan card-summary card-transparent">
                <div className="card-body">
                  <div className="mm-form subscription-form ">
                    <label className="label_modify mm-text-primary ft-weight-700 ">
                      Do you have a coupon?.
                    </label>
                    <div className="mm-form-group mm-form-group2 d-flex">
                      <input
                        type="text"
                        className=" input_modify ft-weight-500 "
                        id="state"
                        placeholder="Enter Code"
                      />
                      <div></div>
                      <p className="mm-text-primary ft-weight-700">Apply</p>
                    </div>
                  </div>
                </div>
              </div>
              <article className="mm-article rm-flex-row-between">
                <h6 className="mm-text-primary ft-weight-700 font-sf-ui-display">
                  Membership Summary
                </h6>
              </article>
              <div className="card card-plan card-summary">
                <div className="card-body">
                  <div className="d-flex mm-card-price-wrap">
                    <div>
                      <p className=" ft-weight-500  mm-text-primary">
                        Subtotal
                      </p>
                    </div>

                    <div>
                      <p className=" ft-weight-600 mm-text-primary">
                        ${selectedPlan?.amount / 100}
                      </p>
                    </div>
                  </div>
                  <div className="d-flex mm-card-price-wrap">
                    <p className=" ft-weight-500 mm-text-primary">Tax</p>
                    <p className=" ft-weight-600 mm-text-primary">$0.00</p>
                  </div>
                  <hr className="mm-hr mm-hr-alter" />
                  <div className="d-flex mm-card-price-wrap mm-pay-wrap">
                    <h6 className=" ft-weight-700 mm-text-primary">item Pay</h6>
                    <h3 className=" ft-weight-700 mm-text-primary">
                      ${selectedPlan?.amount / 100}
                    </h3>
                  </div>
                </div>
              </div>
            </div>

            <div className="subscription-list-wrapper">
              <article className="mm-article rm-flex-row-between">
                <h6 className="mm-text-primary ft-weight-700 font-sf-ui-display">
                  Payment Method
                </h6>
              </article>
              <div className="card card-plan payment-card">
                <div className="card-body pay-via-checkbox">
                  <div className="rm-flex-row-between m-3">
                    <label
                      htmlFor="state"
                      className="label_modify ft-weight-700 mb-0 mm-text-gray1"
                    >
                      Pay Via
                    </label>
                  </div>
                  {cards?.map((card, index) => (
                    <div
                      className="mm-form-group mb-3 checkbox-wrap"
                      key={index}
                    >
                      <label className="check-cont check-cont-alt ft-weight-700">
                        {card?.funding} {card.object} ****{card.last4}
                        <span className="mm-form-link ft-weight-600 ms-2">
                          {card.brand}
                        </span>
                        {index === 0 ? (
                          <span className="mm-form-link ft-weight-600 ms-2">
                            Default Card
                          </span>
                        ) : null}
                        <input
                          type="radio"
                          className="radio"
                          name="checkbox"
                          value={cardPrice ? true : false}
                          onChange={() => handleCardChange(card)}
                        />
                        <span className="checkbox-check"></span>
                      </label>
                    </div>
                  ))}
                  <div
                    className="mm-form-link ft-weight-700 "
                    onClick={addCard}
                  >
                    + Add Credit Card
                  </div>
                </div>
              </div>
            </div>
            <div className="mm-btn-wrapper text-center">
              <button
                type="submit"
                className="theme_btn theme_primary w-100 ft-weight-600 "
              >
                Continue Payment ${selectedPlan?.amount / 100}
              </button>
            </div>
          </form>
        </WithoutHeader>
      ) : (
        <CardComponents setNewCard={setNewCard} />
      )}
    </>
  );
};

export default Checkout;

export const InjectedCheckoutForm = () => {
  return (
    <ElementsConsumer>
      {({ stripe, elements }) => (
        <Checkout stripe={stripe} elements={elements} />
      )}
    </ElementsConsumer>
  );
};
