import URL from "api/constants";
import auth from "api/config";

/**
 * Pass the initial user details to create a new user account.
 * In response, you will receive the sign-up success flag, JWT access token and user details
 * @param {{user: { first_name, last_name, email, password, terms_agreed_at, role }}} user
 * @returns {{success, accessToken, user}} response
 */
export const signUp = (user) => auth.post(URL.signUp, { user });

/**
 * Pass the initial user details to sign in to the user account.
 * In response, you will receive the sign in success flag, JWT access token and user details
 * @param {{user: { first_name, last_name, email, password, terms_agreed_at, role }}} user
 * @returns {{success, accessToken, user}} response
 */
export const signIn = (user) => auth.post(URL.signIn, { user });

/**
 * Pass authorization "Bearer access token" and OTP as parameters
 * @param {string} token
 * @param {*} code
 * @returns {boolean} success
 */
export const verify = (token, code) =>
  auth.put(
    URL.verify,
    { code },
    { headers: { Authorization: `Bearer ${token}` } }
  );

/**
 * Pass authorization "Bearer access token" and user ID as parameters
 * @param {string} token
 * @param {*} userId
 * @returns {boolean} success
 */
export const resend = (token, userId) =>
  auth.put(URL.resend(userId), null, {
    headers: { Authorization: `Bearer ${token}` },
  });

/**
 * Pass the user's registered email address to get the reset token in mail
 * @param {string} email
 * @returns
 */
export const forgotPassword = (email) =>
  auth.put(URL.forgotPassword, { email });

/**
 * Pass the reset token received in mail and the new password
 * @param {string} resetToken
 * @param {string} newPassword
 * @returns
 */
export const resetPassword = (resetToken, newPassword) =>
  auth.put(URL.resetPassword, {
    reset_password_token: resetToken,
    password: newPassword,
    password_confirmation: newPassword,
  });

/**
 * Pass authorization "Bearer access token", user's email, current password and new password
 * @param {string} token
 * @param {string} email
 * @param {string} oldPassword
 * @param {string} newPassword
 * @returns {boolean} success
 */
export const changePassword = (token, email, oldPassword, newPassword) =>
  auth.put(
    URL.changePassword,
    {
      email,
      current_password: oldPassword,
      password: newPassword,
      password_confirmation: newPassword,
    },
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  );

/**
 * Refreshes the authentication of the logged in user without having to login again.
 * @param {string} refreshToken
 * @returns {*} response
 */
export const refreshUser = (refreshToken) =>
  auth.post(URL.refreshUser, null, {
    headers: { "x-refreshtoken": refreshToken },
  });
