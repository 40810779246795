import { ROUTES } from "constant";
import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router";
import useStore from "store";

import HomeJSX from "./Home";

const Home = () => {
  const store = useStore();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const tabs = [
    // {
    //   name: "For You",
    //   path: ROUTES.home,
    // },
    {
      name: "Browse",
      path: ROUTES.browse,
    },
    {
      name: "Favourites",
      path: ROUTES.favourites,
    },
  ];

  const navigateForYou = () => {
    navigate("/home", { replace: true });
  };

  const navigateBrowse = () => {
    navigate("/home/sessions", { replace: true });
  };

  const navigateFavourites = () => {
    navigate("/home/favourites", { replace: true });
  };

  useEffect(() => {
    return () => {};
  }, []);

  const componentProps = {
    navigateForYou,
    navigateBrowse,
    navigateFavourites,
    user: store?.activeUser,
    tabs,
    pathname,
  };

  return <HomeJSX {...componentProps} />;
};

export default Home;
